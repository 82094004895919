<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div class="action-item">
            <div v-for="(item, ind) in value.value" :key="ind">
              <div v-if="item.roleOwner == true">
                <div
                  v-if="
                    user_type == typeOwner ||
                    user_type == typeCDEA ||
                    (item.roleAdmin == true && user_type == typeAdmin)
                  "
                >
                  <a
                    v-if="item.newTab == true"
                    class="d-flex"
                    :href="item.routeName != null ? item.routeName : '/'"
                    target="_blank"
                  >
                    <img :src="item.icon" alt="" class="my-auto" />
                    <p class="ml-2 my-auto">{{ item.text }}</p>
                  </a>
                  <router-link
                    v-else
                    class="d-flex"
                    :to="{ name: item.routeName }"
                  >
                    <img :src="item.icon" alt="" class="my-auto" />
                    <p class="ml-2 my-auto">{{ item.text }}</p>
                  </router-link>
                </div>
              </div>
              <div v-else>
                <router-link class="d-flex" :to="{ name: item.routeName }">
                  <img :src="item.icon" alt="" class="my-auto" />
                  <p class="ml-2 my-auto">{{ item.text }}</p>
                </router-link>
              </div>
            </div>
            <!-- <router-link
              class="d-flex"
              v-for="(item, ind) in value.value"
              :key="ind"
              :to="{ name: item.routeName }"
            >
              <img :src="item.icon" alt="" class="my-auto" />
              <p class="ml-2 my-auto">{{ item.text }}</p>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu1 from "@/assets/img/menu1.png";
import menu3 from "@/assets/img/menu3.png";
import saleMenu from "@/assets/img/sale-manager.png";
import urlMenu from "@/assets/img/url_icon.png";
import inquiryMenu from "@/assets/img/inquiry_icon.png";
import noteMenu from "@/assets/img/note_icon.png";
import userMenu from "@/assets/img/user_icon.png";
import cartMenu from "@/assets/img/cart_icon.png";
import surveyMenu from "@/assets/img/survey.png";
// import emailSettingMenu from "@/assets/img/email_setting.png";
import emailMegaMenu from "@/assets/img/email_newletter.png";
import newsMenu from "@/assets/img/news.png";
import slagMenu from "@/assets/img/slag.png";
import biMenu from "@/assets/img/BI.png";
import newsCategoryMenu from "@/assets/img/news_cateory.png";
import authorMenu from "@/assets/img/author.png";
import menusMenu from "@/assets/img/menu_setting.png";
// import pointSettingMenu from "@/assets/img/point_setting.png";
import { Constants } from "../../utils/constants";

export default {
  name: "DashboardAdminBox",
  data() {
    return {
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      typeAdmin: Constants.USER_TYPE_DEFAULT["admin"],
      listFunction: [
        {
          title: "売上管理",
          value: [
            {
              icon: menu1,
              text: "注文管理",
              routeName: "ListOrder",
            },
            {
              icon: saleMenu,
              text: "売上管理",
              routeName: "Menu SaleManagerment",
            },
          ],
        },
        {
          title: "URL",
          value: [
            {
              icon: menu3,
              text: "URL作成",
              routeName: "CreateURL",
            },
            // {
            //   icon: iconDefault,
            //   text: "販売URL",
            //   routeName: "",
            // },
            {
              icon: urlMenu,
              text: "URL設計管理",
              routeName: "URLManager",
            },
          ],
        },
        {
          title: "ユーザー管理",
          value: [
            {
              icon: userMenu,
              text: "ユーザー管理",
              routeName: "list user",
              roleOwner: true,
              roleAdmin: true,
            },
            {
              icon: inquiryMenu,
              text: "お問い合わせ管理",
              routeName: "list inquiry",
            },
            {
              icon: noteMenu,
              text: "ノート管理",
              routeName: "List Note",
            },
            {
              icon: surveyMenu,
              text: "質問管理",
              routeName: "Chat Admin",
            },
            {
              icon: cartMenu,
              text: "カート利用中のユーザー一覧",
              routeName: "List Cart",
            },
          ],
        },
        {
          title: "メール",
          value: [
            // {
            //   icon: emailSettingMenu,
            //   text: "メール設定",
            //   routeName: "email setting",
            // },
            {
              icon: emailMegaMenu,
              text: "登録メルマガ管理",
              routeName: "List Mail Mega",
            },
          ],
        },
        {
          title: "ニュース",
          value: [
            {
              icon: newsMenu,
              text: "ニュース管理",
              routeName: "list new admin",
            },
            {
              icon: newsCategoryMenu,
              text: "ニュースカテゴリ管理",
              routeName: "list category new",
            },
          ],
        },
        {
          title: "スラグ",
          value: [
            {
              icon: slagMenu,
              text: "スラグ管理",
              routeName: "list slag",
            },
          ],
        },
        {
          title: "データ",
          value: [
            {
              icon: biMenu,
              text: "データ確認",
              routeName:
                localStorage.getItem(
                  Constants.BI_SHOP +
                    (this.$route.params.shopId
                      ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                      : "_" + Constants.DOMAIN)
                ) &&
                localStorage.getItem(
                  Constants.BI_SHOP +
                    (this.$route.params.shopId
                      ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                      : "_" + Constants.DOMAIN)
                ) != "null"
                  ? localStorage.getItem(
                      Constants.BI_SHOP +
                        (this.$route.params.shopId
                          ? "_" +
                            Constants.DOMAIN +
                            "_" +
                            this.$route.params.shopId
                          : "_" + Constants.DOMAIN)
                    )
                  : "",
              roleOwner: true,
              newTab: true,
            },
          ],
        },
        {
          title: "著者管理",
          value: [
            {
              icon: authorMenu,
              text: "著者管理",
              routeName: "ListAuthor",
            },
          ],
        },
        {
          title: "メニュー",
          value: [
            {
              icon: menusMenu,
              text: "メニュー設定",
              routeName: "menuManager",
              roleOwner: true,
            },
          ],
        },
        // {
        //   title: "ポイント機能",
        //   value: [
        //     {
        //       icon: pointSettingMenu,
        //       text: "ポイント設定",
        //       routeName: "list point",
        //       roleOwner: true,
        //     },
        //     // {
        //     //   icon: iconDefault,
        //     //   text: "ポイント定義",
        //     //   routeName: "Create Point Definition",
        //     //   roleOwner: true,
        //     // },
        //   ],
        // },
      ],
    };
  },
  created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          if (e.routeName != null && e.text != "データ確認") {
            e.routeName += " domain";
          }
        });
      });
    }
  },
};
</script>
